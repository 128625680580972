.layout {
    height:100%;
}
.header-logo {
    max-height: 100%;
    max-width: 100%;
    padding: 16px 0px;
}
.max-height_inherit {
    max-height: inherit
}
.no-text-selection {
    -webkit-user-select: none; /* Chrome/Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+ */

    /* Rules below not implemented in browsers yet */
    -o-user-select: none;
    user-select: none;
}

.stop-scrolling {
    height: 100%;
    overflow: hidden;
}
.editable-frameviewer-item {
    opacity: 0.4;
}
.editable-frameviewer-item:hover {
    opacity: 1;
}

.selected-frame {
    background-color: #fff1b8;
}

.active-board {
    background-color: #fff1b8;
}
.frame-viewer-coords-icon:hover {
    color: #096dd9
}
.frame-viewer-select-icon:hover {
    color: #096dd9
}
.frame-viewer-copy-icon:hover {
    color: #096dd9
}
.frame-viewer-move-icon:hover {
    color: #096dd9
}
.frame-viewer-delete-icon:hover {
    color: #cf1322
}
